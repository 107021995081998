// @flow

import React from 'react'
import type { TFunction } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'
import { useRouter } from 'found'

import { Button, DropDownMenu, DropDownMenuItem, Icon } from 'care-ui'
import ArrowDown from 'care-ui/atoms/icons/arrow-down.svg'

import { TrackableDropdownUser } from './query/TrackableDropdown'

import type { TrackableDropdown_user } from './query/__generated__/TrackableDropdown_user.graphql'

type PropsType = {
  translation: TFunction,
  user: ?TrackableDropdown_user,
}

const TrackableDropdownBase = ({ user, translation }: PropsType) => {
  const { router } = useRouter()

  const { summaryUserTrackables } = user || {}
  const { nodes: userTrackables } = summaryUserTrackables || {}

  const routeToBOCByTrackableId = ({ trackable_id }) => () => {
    router.push({
      name: 'user_score_card',
      params: {
        id: user?.id,
        trackable_id,
      },
    })
  }

  const dropDownItems = userTrackables?.map((userTrackable) => {
    const { trackable } = userTrackable || {}
    const routeParams = {
      trackable_id: trackable.id,
    }

    return (
      <DropDownMenuItem
        key={`${trackable.label}-dropdown`}
        onClick={routeToBOCByTrackableId(routeParams)}
        dataTestId={trackable.label}
        ariaLabel={trackable.label}
      >
        {trackable.label}
      </DropDownMenuItem>
    )
  })

  return (
    <DropDownMenu
      position="end"
      dropDownTrigger={
        <Button
          variant="text"
          size="md"
          ariaLabel="See more details"
          dataTestId="see-more-details"
        >
          {translation('see_more_details')}
          <Icon as={ArrowDown} scale={0.6} />
        </Button>
      }
    >
      <>{dropDownItems}</>
    </DropDownMenu>
  )
}

const TrackableDropdown = createFragmentContainer(TrackableDropdownBase, {
  user: TrackableDropdownUser,
})

export default TrackableDropdown
