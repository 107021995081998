// @flow

import { useCurrentUserRoles } from 'platform_web/hooks/useCurrentUserRoles'

type RoleType = 'LEAD_CLINICIAN'

type ACTIONS_FOR_CLINICIAN_TYPE = Array<{
  actionName: string,
  roles: Array<RoleType>,
}>

const ACTIONS_FOR_CLINICIAN: ACTIONS_FOR_CLINICIAN_TYPE = [
  {
    actionName: 'assignIndividuals',
    roles: ['LEAD_CLINICIAN'],
  },
]

const useClinicianActions = () => {
  const {
    isMultiRolesEnabled,
    roleType: singleRoleType,
    currentUserRoles,
  } = useCurrentUserRoles()

  const roleTypes = isMultiRolesEnabled ? currentUserRoles : [singleRoleType]

  const getAllowedActions = () => {
    const allowedActions: Array<string> = ACTIONS_FOR_CLINICIAN.filter(
      (action) => roleTypes.some((role) => action.roles.includes(role)),
    ).map((action) => action.actionName)

    return allowedActions
  }

  return {
    actions: getAllowedActions(),
  }
}

export default useClinicianActions
